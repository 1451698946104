import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useParams,
  Redirect,
  useLocation,
} from 'react-router-dom';

import Panel from '../features/ui/authentication/containers/Panel';
import {
  DEFAULT_VIEW,
  View,
} from '../features/ui/authentication/helpers/constants/authentication';
import { getAuthLink } from '../helpers/navigation';
import { getDocumentTitle } from '../helpers';
import {
  getCode,
  getDefaultRoute,
  getEmail,
  isSignUpRelatedView,
} from '../features/ui/authentication/helpers/functions/navigation';
import { selectAuthenticationFulfilled } from '../features/user/userSelectors';
import { authenticate } from '../features/user/userSlice';
import useDidMount from '../hooks/useDidMount';
import { isSignUpEnabled } from '../helpers/functions/utils/appConfig';

const locationValidators = {
  [View.signUpSent]: (location) => {
    return location.state?.email;
  },
  [View.signUpConfirm]: (location) => {
    const code = getCode(location.search);
    const email = getEmail(location.search);

    return code && email;
  },
  [View.recoverPassword]: (location) => {
    const code = getCode(location.search);
    const email = getEmail(location.search);

    return code && email;
  },
  [View.forgotPasswordSent]: (location) => {
    return location.state?.email;
  },
  [View.newPassword]: (location) => {
    return location.state?.user;
  },
};

const Authentication = () => {
  const { view = DEFAULT_VIEW } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();

  const authenticationFulfilled = useSelector(selectAuthenticationFulfilled);
  const isLocationValid = locationValidators[view] || (() => true);

  useDidMount(() => {
    document.title = getDocumentTitle();
    dispatch(authenticate());
  });

  if (
    !isSignUpEnabled()
    && isSignUpRelatedView(view)
  ) {
    return (
      <Redirect to={getAuthLink(View.logIn)} />
    );
  }

  let result;

  // bypass signUpConfirm and recoverPassword views for cases when
  // user tries to navigate to confirmation link from email while already signed in
  if (
    authenticationFulfilled
    && view !== View.signUpConfirm
    && view !== View.recoverPassword
  ) {
    result = <Redirect to={getDefaultRoute()} />;
  } else if (isLocationValid(location)) {
    result = <Panel />;
  } else {
    result = <Redirect to={getAuthLink(View.logIn)} />;
  }

  return result;
};

export default Authentication;
