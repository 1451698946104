import React, {
  Fragment,
  Suspense,
} from 'react';
import ReactDOM from 'react-dom';
import {
  createMuiTheme,
  StylesProvider,
  ThemeProvider,
} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Amplify } from '@aws-amplify/core';
import { Provider } from 'react-redux';

import './i18n';
import { setupStore } from './app/store';
import Routers from './routes';
import app from '../public/configs/app.json';
import BootstrapLoading from './components/Loading/BootstrapLoading';
import NotificationsProvider from './features/notifications/containers/NotificationsProvider';
import { init } from './features/ui/applicationShell/helpers/functions/sentry';
import { getAWSConfig } from './helpers/functions/utils/vendorConfigs/aws';
import { getPalette } from './helpers/functions/utils/appConfig';

init();
Amplify.configure(getAWSConfig());
Amplify.Logger.LOG_LEVEL = app.logging ? 'DEBUG' : 'ERROR';
window.LOG_LEVEL = app.logging ? 'DEBUG' : 'ERROR';

const palette = getPalette();
const theme = createMuiTheme({
  palette: {
    primary: {
      main: palette?.primary?.main || '#33691E',
      light: palette?.primary?.light || '#629749',
      dark: palette?.primary?.dark || '#003D00',
    },
    secondary: {
      main: palette?.secondary?.main || '#8E0000',
      light: palette?.secondary?.light || '#EF6443',
    },
    error: {
      // used in upload-files popup
      main: palette?.error?.main || '#C62828',
    },
    text: {
      ...(palette?.text?.primary ? { primary: palette.text.primary } : {}),
      ...(palette?.text?.secondary ? { secondary: palette.text.secondary } : {}),
      ...(palette?.text?.disabled ? { disabled: palette.text.disabled } : {}),
    },
    action: {
      ...(palette?.action?.disabled ? { disabled: palette.action.disabled } : {}),
    },
  },
  zIndex: {
    modal: 1360, // to overflow drawer
  },
  overrides: {
    MuiTypography: {
      h5: {
        fontSize: '20px',
      },
      body1: {
        fontSize: '14px',
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 700,
      lg: 1200,
      xl: 1536,
    },
  },
});

const App = () => {
  return (
    <Suspense fallback={<BootstrapLoading />}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Routers />
      </MuiPickersUtilsProvider>
    </Suspense>
  );
};

const WrappedApp = () => (
  <Provider store={setupStore()}>
    <ThemeProvider theme={theme}>
      <StylesProvider injectFirst>
        <NotificationsProvider>
          <App />
        </NotificationsProvider>
      </StylesProvider>
    </ThemeProvider>
  </Provider>
);

ReactDOM.render(
  <Fragment>
    <CssBaseline />
    <WrappedApp />
  </Fragment>,
  document.getElementById('root'),
);
