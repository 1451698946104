import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Auth } from '@aws-amplify/auth';

import FederatedLogInPanel from '../common/FederatedLogIn';
import SignUpForm from '../../../../components/SignUpForm/SignUpForm';
import TwoSidePanel from '../../../../components/TwoSidePanel';
import {
  prepareAttribute,
  prepareEmailAttribute,
} from '../../../../helpers/functions/authentication';
import { SignUpFormFields } from '../../../../types/ui';
import { getAuthLink } from '../../../../../../../helpers/navigation';
import { View } from '../../../../helpers/constants/authentication';
import { errorNotify } from '../../../../../../notifications/helpers/functions/notify';
import { CustomError } from '../../../../../../../helpers/functions/utils/errorHandling';

import '../../../../components/common/index.scss';
import './index.scss';

const SignUpPanel = ({
  onLoading,
}: {
  onLoading: (loading: boolean) => void;
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSignUpClick = async (formState: SignUpFormFields) => {
    const preparedEmail = prepareEmailAttribute(formState.email);

    onLoading(true);

    try {
      await Auth.signUp({
        username: preparedEmail,
        password: formState.password,
        attributes: {
          email: preparedEmail,
          given_name: prepareAttribute(formState.firstName),
          family_name: prepareAttribute(formState.lastName),
          phone_number: prepareAttribute(formState.phone),
        },
      });
      history.push({
        pathname: getAuthLink(View.signUpSent),
        state: {
          email: preparedEmail,
        },
      });
    } finally {
      onLoading(false);
    }
  };

  const onSignUpUnknownError = (e: Error) => {
    errorNotify({
      error: new CustomError('[UI Sign Up] Unable to sign up.', {
        cause: e,
      }),
      message: e.message,
      dispatch,
    });
  };

  return (
    <div className="authentication-panel sign-up-panel">
      <Typography
        classes={{
          root: 'authentication-panel__header',
        }}
        variant="h1"
      >
        Get started today
      </Typography>
      <TwoSidePanel
        left={<FederatedLogInPanel />}
        right={(
          <SignUpForm
            onSignUpClick={handleSignUpClick}
            onSignUpUnknownError={onSignUpUnknownError}
          />
)}
      />
    </div>
  );
};

export default SignUpPanel;
