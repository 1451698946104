import { DEFAULT_POLYGON_MIN_AREA } from '../../../../helpers';
import { DataClassificationType } from '../../../../helpers/constants/entities/vectorAnalysisMap';

export const MIN_NUMBER_OF_ZONES = 3;
export const MAX_NUMBER_OF_ZONES = 11;
export const SPATIALLY_LOCALIZED_TYPE_MAX_NUMBER_OF_ZONES = 50;
export const DEFAULT_NUMBER_OF_ZONES = 5;

export const DEFAULT_ZONES_CREATION_SETTINGS = {
  name: '',
  numberOfZones: DEFAULT_NUMBER_OF_ZONES,
  polygonMinArea: DEFAULT_POLYGON_MIN_AREA,
  dataClassificationType: DataClassificationType.equalCount,
  useInterpolatedData: true,
};
