import React from 'react';
import { useTranslation } from 'react-i18next';

import AntSwitch from '../../../../../components/AntSwitch';
import FarmSelect from '../../../../farms/containers/FarmSelect';
import { useAppDispatch, useAppSelector } from '../../../../../app/store/helpers/functions';
import { fetchLabels } from '../../../../labels/labelsSlice';
import { selectLabelsMap } from '../../../../labels/labelsSelectors';
import { selectFarmUuid, selectFilter } from '../../fieldsListSelectors';
import { selectLabelsExpanded } from '../../../fieldProfiler/fieldProfilerSelectors';
import { updateFilters } from '../../fieldsListSlice';
import { setLabelsExpanded } from '../../../fieldProfiler/fieldProfilerSlice';
import type { AutocompleteItem } from '../../../autocomplete/types/autocomplete';
import type { TransformedFarm } from '../../../../farms/types/farm';
import { sendFeatureUsage } from '../../../../../helpers/analytics';
import { transformToFilterUpdates, transformToAutocompleteValue } from '../../helpers/functions/filters';
import LabelNameFieldsFilter from '../../components/LabelNameFieldsFilter';

import './index.scss';

export default function FieldsListFilter({
  withLabelsExpandSwitcher = true,
  withCreateFarm,
}: {
  withLabelsExpandSwitcher?: boolean,
  withCreateFarm?: boolean,
}) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  // @ts-expect-error
  const labelsMap = useAppSelector(selectLabelsMap);
  const farmUuid = useAppSelector(selectFarmUuid);
  const filter = useAppSelector(selectFilter);
  const labelsExpanded = useAppSelector(selectLabelsExpanded);

  const handleGetLabelsOptions = () => {
    dispatch(fetchLabels());
  };

  const handleFilterChange = (value: AutocompleteItem[]) => {
    const filterUpdates = transformToFilterUpdates(value, labelsMap);

    sendFeatureUsage('fields', 'search', {
      labels: (filterUpdates.labels || []).length,
      name: filterUpdates.name != null,
    });

    dispatch(updateFilters({
      filter: filterUpdates,
    }));
  };

  const handleFarmChange = (value: TransformedFarm | null) => {
    dispatch(updateFilters({
      farm: value?.uuid ?? '',
    }));
  };

  const handleLabelsSwitchClick = (checked: boolean) => {
    dispatch(setLabelsExpanded(checked));
  };

  return (
    <div className="fields-list-filter">
      <FarmSelect
        withCreateFarm={withCreateFarm}
        selectedFarmUuid={farmUuid}
        onFarmChange={handleFarmChange}
      />
      <LabelNameFieldsFilter
        options={labelsMap}
        value={transformToAutocompleteValue(filter)}
        onFilterChange={handleFilterChange}
        onGetOptions={handleGetLabelsOptions}
      />
      {
        withLabelsExpandSwitcher
        && (
          <div className="fields-list-filter__switch-wrapper">
            <AntSwitch
              isChecked={labelsExpanded}
              label={t('field-profiler.tools-panel.expand-all-labels')}
              onValueChange={handleLabelsSwitchClick}
            />
          </div>
        )
      }
    </div>
  );
}
