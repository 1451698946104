import React from 'react';

import { useAppDispatch, useAppSelector } from '../../../../../app/store/helpers/functions';
import { selectSelectedField } from '../../zonesOpsSelectors';
import { selectFilter, selectFarmUuid } from '../../../fieldsList/fieldsListSelectors';
import { selectLabelsExpanded } from '../../../fieldProfiler/fieldProfilerSelectors';
import { selectApiKey, selectAreaUnit } from '../../../../user/userSelectors';
import { useGetFarmsQuery } from '../../../../farms/farmsAPI';
import {
  getFieldsTableRows,
  isCollapsibleLabelsNestedTableRow,
  isDefaultFieldTableRow,
} from '../../../fieldsList/helpers/functions/tableRows';
import FieldProfilerTable from '../../../fieldsList/components/Table';
import { getBaseTableColumns } from '../../../fieldProfiler/helpers/functions/tableColumns';
import { FieldTableRow } from '../../../fieldsList/types/tableRow';
import { setSelectedField } from '../../zonesOpsSlice';
import useInfiniteFieldsList from '../../../../fields/hooks/useInfiniteFieldsList';

export default function FieldsListTable() {
  const dispatch = useAppDispatch();
  const {
    data: farms,
    isFetching: farmsFetching,
  } = useGetFarmsQuery();

  const { fieldUuid } = useAppSelector(selectSelectedField);
  const apiKey = useAppSelector(selectApiKey);
  const areaUnit = useAppSelector(selectAreaUnit);
  const labelsExpanded = useAppSelector(selectLabelsExpanded);
  const filter = useAppSelector(selectFilter);
  const farmUuid = useAppSelector(selectFarmUuid);

  const {
    fields,
    fieldsLoading,
    fieldsLoadComplete,
    triggerNextPage,
  } = useInfiniteFieldsList({
    farmUuid,
    fieldName: filter.name,
    labels: filter.labels,
  });

  const handleLoadMoreRows = () => {
    triggerNextPage();
  };

  const handleRowClick = (row: FieldTableRow | null) => {
    if (!row) {
      return;
    }

    let uuid = '';

    if (isDefaultFieldTableRow(row)) {
      uuid = row.id;
    } else if (isCollapsibleLabelsNestedTableRow(row)) {
      uuid = row.fieldUuid;
    }

    dispatch(setSelectedField({
      farmUuid: row.farmUuid || '',
      uuid,
    }));
  };

  const rows = getFieldsTableRows({
    fields,
    farms: farms || [],
    apiKey,
  });
  const columns = getBaseTableColumns({
    selectable: false,
    areaUnit,
  });

  return (
    <FieldProfilerTable
      rows={rows}
      columns={columns}
      loadComplete={fieldsLoadComplete}
      loading={fieldsLoading || farmsFetching}
      selectedFieldUuid={fieldUuid}
      labelsExpanded={labelsExpanded}
      onLoadMoreRows={handleLoadMoreRows}
      onRowClick={handleRowClick}
    />
  );
}
