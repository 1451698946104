import React, { useCallback } from 'react';
import clsx from 'clsx';

import BackdropLoading from '../../../../../components/Loading/BackdropLoading';
import InfiniteTable from '../../../tableView/components/InfiniteTable';
import TableHeadContent from '../../../tableView/components/TableHeadContent';
import TableRowContent from '../../../tableView/components/TableRowContent';
import type { DefaultFieldTableRow, FieldTableRow } from '../../types/tableRow';
import type { TableColumn } from '../../../tableView/types/column';
import {
  isCollapsibleLabelsNestedTableRow,
  isDefaultFieldTableRow,
} from '../../helpers/functions/tableRows';
import useLabelsExpanded from '../../../tableView/hooks/useLabelsExpanded';
import { isRowExpanded } from '../../../tableView/helpers/functions/rows';

import './index.scss';

const isRowSelected = (row: FieldTableRow | null, selectedFieldUuid?: string) => {
  let isSelected = false;

  if (!row) {
    return isSelected;
  }

  if (isDefaultFieldTableRow(row)) {
    isSelected = row.id === selectedFieldUuid;
  } else if (isCollapsibleLabelsNestedTableRow(row)) {
    isSelected = row.fieldUuid === selectedFieldUuid;
  }

  return isSelected;
};

export default function FieldProfilerTable({
  loading,
  rows,
  columns,
  editMode = false,
  selectedFieldUuid,
  loadComplete,
  labelsExpanded,
  onLoadMoreRows,
  onRowClick,
}: {
  loading: boolean,
  rows: FieldTableRow[],
  columns: TableColumn<DefaultFieldTableRow>[],
  editMode?: boolean,
  selectedFieldUuid?: string;
  loadComplete: boolean,
  labelsExpanded: boolean,
  onLoadMoreRows: () => void,
  onRowClick?: (r: FieldTableRow | null) => void,
}) {
  const { expanded, handleExpandedChange } = useLabelsExpanded(labelsExpanded);

  const headerContentRenderer = useCallback(() => {
    return (
      <TableHeadContent columns={columns} />
    );
  }, [columns]);

  const rowRenderer = useCallback((row: FieldTableRow) => {
    const isSelected = isRowSelected(row, selectedFieldUuid);
    const isExpanded = isRowExpanded(row, expanded, labelsExpanded);

    return (
      <TableRowContent
        columns={columns}
        row={row}
        editMode={editMode}
        isSelected={isSelected}
        isExpanded={isExpanded}
        onRowExpandedChange={handleExpandedChange}
      />
    );
  }, [selectedFieldUuid, columns, editMode, expanded, labelsExpanded, handleExpandedChange]);

  const getRowClasses = useCallback((row: FieldTableRow | null) => {
    return {
      selected: clsx({
        'infinite-table__row_selected': isRowSelected(row, selectedFieldUuid),
      }),
    };
  }, [selectedFieldUuid]);

  return (
    <div className="fields-list-table">
      {
        loading && <BackdropLoading />
      }
      <InfiniteTable
        data={rows}
        loadComplete={loadComplete}
        columnsAmount={columns.length}
        rowRenderer={rowRenderer}
        getRowClasses={getRowClasses}
        headerContentRenderer={headerContentRenderer}
        onLoadMoreRows={onLoadMoreRows}
        onRowClick={onRowClick}
      />
    </div>
  );
}
