import React from 'react';

import {
  selectFilter,
  selectFarmUuid,
} from '../../../fieldsList/fieldsListSelectors';
import { selectLabelsExpanded } from '../../../fieldProfiler/fieldProfilerSelectors';
import { selectApiKey, selectAreaUnit } from '../../../../user/userSelectors';
import { useGetFarmsQuery } from '../../../../farms/farmsAPI';
import {
  getFieldsTableRows,
} from '../../../fieldsList/helpers/functions/tableRows';
import FieldProfilerTable from '../../../fieldsList/components/Table';
import { getBaseTableColumns } from '../../../fieldProfiler/helpers/functions/tableColumns';
import {
  selectSelectedFieldsUuids,
} from '../../batchAnalyticsSelectors';
import { getRowsSelection } from '../../../tableView/helpers/functions/rows';
import { setFieldsSelection, toggleFieldSelection } from '../../batchAnalyticsSlice';
import { TransformedField } from '../../../../field/types/field';
import { DefaultFieldTableRow } from '../../../fieldsList/types/tableRow';
import { useAppDispatch, useAppSelector } from '../../../../../app/store/helpers/functions';
import useInfiniteFieldsList from '../../../../fields/hooks/useInfiniteFieldsList';

export default function FieldsListTable() {
  const dispatch = useAppDispatch();
  const {
    data: farms,
    isFetching: farmsFetching,
  } = useGetFarmsQuery();

  const selectedFieldsUuids = useAppSelector(selectSelectedFieldsUuids);
  const apiKey = useAppSelector(selectApiKey);
  const areaUnit = useAppSelector(selectAreaUnit);
  const labelsExpanded = useAppSelector(selectLabelsExpanded);
  const filter = useAppSelector(selectFilter);
  const farmUuid = useAppSelector(selectFarmUuid);

  const {
    fields,
    fieldsLoading,
    fieldsLoadComplete,
    triggerNextPage,
  } = useInfiniteFieldsList({
    farmUuid,
    fieldName: filter.name,
    labels: filter.labels,
  });

  const handleLoadMoreRows = () => {
    triggerNextPage();
  };

  const handleHeadCheckboxClick = (selected: boolean) => {
    const uuids = fields.map((field: TransformedField) => {
      return {
        fieldUuid: field.uuid,
        farmUuid: field.farmUuid || '',
      };
    });

    dispatch(setFieldsSelection({
      uuids,
      selected,
    }));
  };

  const handleRowCheckboxClick = (row: DefaultFieldTableRow) => {
    dispatch(toggleFieldSelection({
      fieldUuid: row.id,
      farmUuid: row.farmUuid || '',
    }));
  };

  const rows = getFieldsTableRows({
    fields,
    farms: farms || [],
    apiKey,
    checkedUuids: selectedFieldsUuids,
  });
  const rowsSelection = getRowsSelection(rows);
  const columns = getBaseTableColumns({
    selectable: true,
    areaUnit,
    rowsSelection,
    onHeadCheckboxClick: handleHeadCheckboxClick,
    onRowCheckboxClick: handleRowCheckboxClick,
  });

  return (
    <FieldProfilerTable
      rows={rows}
      columns={columns}
      labelsExpanded={labelsExpanded}
      loadComplete={fieldsLoadComplete}
      loading={fieldsLoading || farmsFetching}
      onLoadMoreRows={handleLoadMoreRows}
    />
  );
}
