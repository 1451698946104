import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';

import {
  View,
  DEFAULT_VIEW,
} from '../../helpers/constants/authentication';
import { getCode } from '../../helpers/functions/navigation';
import AuthenticationHeader from '../../components/AuthenticationHeader';
import BackdropLoading from '../../../../../components/Loading/BackdropLoading';
import useDidMount from '../../../../../hooks/useDidMount';
import views from './config';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    minHeight: '100vh',
    padding: '24px 16px',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'white',
    backgroundImage: 'none',
    [theme.breakpoints.up('md')]: {
      backgroundImage: 'url(../../../../../../assets/auth-bg.webp)',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: '75%',
      alignItems: 'center',
    },
    [theme.breakpoints.up('xl')]: {
      backgroundImage: 'url(../../../../../../assets/auth-bg-xl.webp)',
      backgroundPosition: 'center',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '24px',
    backgroundColor: 'white',
    padding: '0',
    borderRadius: '20px',
    [theme.breakpoints.up('md')]: {
      padding: '40px',
    },
  },
}));

const AuthenticationPanelContainer = () => {
  const { view = DEFAULT_VIEW } = useParams<{ view?: View }>();
  const [loading, setLoading] = useState(false);
  const Component = views[view];

  const classes = useStyles();

  useDidMount(() => {
    const code = getCode(window.location.search);

    // Display loader while getting redirected by auth provider
    setLoading(!!code);
  });

  return (
    <div className={classes.wrapper}>
      <div className={classes.content}>
        { loading && <BackdropLoading /> }
        <AuthenticationHeader />
        <Component onLoading={setLoading} />
      </div>
    </div>
  );
};

export default AuthenticationPanelContainer;
