import React, {
  useState,
  ChangeEvent,
  useMemo,
} from 'react';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import AccountBoxSharpIcon from '@material-ui/icons/AccountBoxSharp';
import EmailSharpIcon from '@material-ui/icons/EmailSharp';
import LockSharpIcon from '@material-ui/icons/LockSharp';
import PhoneAndroidSharpIcon from '@material-ui/icons/PhoneAndroidSharp';

import Button from '../../../../../components/Button';
import TextField from '../../../../../components/TextField';
import Link from '../../../../../components/Link';
import {
  getMultilineHelperText,
  hasErrors,
} from '../../helpers/functions/authentication';
import { getPasswordHelperTexts } from '../../helpers/functions/password';
import { getAuthLink } from '../../../../../helpers/navigation';
import { View } from '../../helpers/constants/authentication';
import { HelperTextType } from '../../helpers/constants/helperTexts';
import type {
  FormHelperTexts,
  HelperText,
  SignUpFormField,
  SignUpFormFields,
} from '../../types/ui';
import { SIGN_UP_ERRORS } from '../../helpers/constants/errors';

import '../common/index.scss';

const formHelperInitialTexts = {
  firstName: [],
  lastName: [],
  password: [],
  email: [],
  phone: [],
};

const SignUpForm = ({
  onSignUpClick,
  onSignUpUnknownError,
}: {
  onSignUpClick: (formState: SignUpFormFields) => Promise<void>;
  onSignUpUnknownError: (error: Error) => void;
}) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [formState, setFormState] = useState({
    firstName: '',
    lastName: '',
    password: '',
    email: '',
    phone: '',
  });
  const [formHelperTexts, setFormHelperTexts] = useState<FormHelperTexts<SignUpFormField>>(formHelperInitialTexts);

  const handlePasswordInputButtonClick = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleFieldChange = (field: SignUpFormField, value: SignUpFormFields[SignUpFormField]) => {
    let fieldHelperTexts: HelperText[] = [];

    if (field === 'password') {
      fieldHelperTexts = getPasswordHelperTexts(value);
    } else {
      fieldHelperTexts = [];
    }

    setFormState((prevFormState) => {
      return {
        ...prevFormState,
        [field]: value,
      };
    });
    setFormHelperTexts((prevFormHelperTexts) => {
      return {
        ...prevFormHelperTexts,
        [field]: fieldHelperTexts,
      };
    });
  };

  const handleSignUpClick = async () => {
    setFormHelperTexts(formHelperInitialTexts);

    try {
      await onSignUpClick(formState);
    } catch (e) {
      const error = e as Error;
      const signUpError = SIGN_UP_ERRORS.find((err) => {
        return err.regexp.test(error.message);
      });

      if (signUpError) {
        setFormHelperTexts((prevFormErrors) => {
          return {
            ...prevFormErrors,
            [signUpError.field]: [
              {
                label: signUpError.helperText,
                type: HelperTextType.error,
              },
            ],
          };
        });
      } else {
        onSignUpUnknownError(error);
      }
    }
  };

  const signUpButtonDisabled = useMemo(() => {
    const hasRequiredFields = Object.values(formState).every((value) => value.trim());

    return !hasRequiredFields || hasErrors(formHelperTexts);
  }, [formHelperTexts, formState]);

  const getFieldErrorState = (field: SignUpFormField) => {
    return formHelperTexts[field].some(({ type }) => {
      return type === HelperTextType.error;
    });
  };

  return (
    <form
      name='Sign up form'
      className="authentication-panel__form"
    >
      <TextField
        value={formState.firstName}
        error={getFieldErrorState('firstName')}
        helperText={getMultilineHelperText(formHelperTexts.firstName)}
        placeholder="First name"
        InputProps={{
          classes: {
            root: 'authentication-panel__input',
            error: 'textfield__input_error',
          },
          startAdornment: (
            <AccountBoxSharpIcon
              fontSize="small"
              className="authentication-panel__input-icon authentication-panel__input-icon_start"
            />
          ),
        }}
        FormHelperTextProps={{
          classes: {
            root: 'authentication-panel__input-helper-text',
          },
        }}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          handleFieldChange('firstName', e.target.value);
        }}
      />
      <TextField
        value={formState.lastName}
        error={getFieldErrorState('lastName')}
        helperText={getMultilineHelperText(formHelperTexts.lastName)}
        placeholder="Last name"
        InputProps={{
          classes: {
            root: 'authentication-panel__input',
            error: 'textfield__input_error',
          },
          startAdornment: (
            <AccountBoxSharpIcon
              fontSize="small"
              className="authentication-panel__input-icon authentication-panel__input-icon_start"
            />
          ),
        }}
        FormHelperTextProps={{
          classes: {
            root: 'authentication-panel__input-helper-text',
          },
        }}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          handleFieldChange('lastName', e.target.value);
        }}
      />
      <TextField
        value={formState.email}
        error={getFieldErrorState('email')}
        helperText={getMultilineHelperText(formHelperTexts.email)}
        placeholder="Email"
        InputProps={{
          classes: {
            root: 'authentication-panel__input',
            error: 'textfield__input_error',
          },
          startAdornment: (
            <EmailSharpIcon
              fontSize="small"
              className="authentication-panel__input-icon authentication-panel__input-icon_start"
            />
          ),
        }}
        FormHelperTextProps={{
          classes: {
            root: 'authentication-panel__input-helper-text',
          },
        }}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          handleFieldChange('email', e.target.value);
        }}
      />
      <TextField
        value={formState.password}
        error={getFieldErrorState('password')}
        helperText={getMultilineHelperText(formHelperTexts.password)}
        placeholder="Password 8+ characters"
        type={passwordVisible ? 'text' : 'password'}
        autoComplete="off"
        InputProps={{
          classes: {
            root: 'authentication-panel__input',
            adornedEnd: 'textfield__input_adorned-end',
            error: 'textfield__input_error',
          },
          startAdornment: (
            <LockSharpIcon
              fontSize="small"
              className="authentication-panel__input-icon authentication-panel__input-icon_start"
            />
          ),
          endAdornment: (
            <IconButton
              className="authentication-panel__input-icon-button"
              size="small"
              disableRipple
              onClick={handlePasswordInputButtonClick}
            >
              {
                    passwordVisible
                      ? (
                        <VisibilityOffIcon
                          fontSize="small"
                          className="authentication-panel__input-icon"
                        />
                      )
                      : (
                        <VisibilityIcon
                          fontSize="small"
                          className="authentication-panel__input-icon"
                        />
                      )
                  }
            </IconButton>
          ),
        }}
        FormHelperTextProps={{
          classes: {
            root: 'authentication-panel__input-helper-text',
          },
        }}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          handleFieldChange('password', e.target.value);
        }}
      />
      <TextField
        value={formState.phone}
        error={getFieldErrorState('phone')}
        helperText={getMultilineHelperText(formHelperTexts.phone)}
        placeholder="Phone number"
        InputProps={{
          classes: {
            root: 'authentication-panel__input',
            error: 'textfield__input_error',
          },
          startAdornment: (
            <PhoneAndroidSharpIcon
              fontSize="small"
              className="authentication-panel__input-icon authentication-panel__input-icon_start"
            />
          ),
        }}
        FormHelperTextProps={{
          classes: {
            root: 'authentication-panel__input-helper-text',
          },
        }}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          handleFieldChange('phone', e.target.value);
        }}
      />
      <Button
        className="authentication-panel__button"
        variant="contained"
        color="primary"
        disabled={signUpButtonDisabled}
        onClick={handleSignUpClick}
      >
        Get started now
      </Button>
      <div className="authentication-panel__account-option">
        <Typography
          className="authentication-panel__tip"
        >
          Already have account?
        </Typography>
        <Link
          to={getAuthLink(View.logIn)}
        >
          Log in
        </Link>
      </div>
    </form>
  );
};

export default SignUpForm;
