import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import AuthenticationRedirector from '../routes/redirectors/Authentication';
import PricingRedirector from '../routes/redirectors/Pricing';
import Table from '../features/ui/fieldProfiler/containers/Table';
import ToolsPanel from '../features/ui/fieldProfiler/containers/ToolsPanel';
import Legend from '../features/ui/mapView/containers/Legend';
import TablePanel from '../features/ui/fieldProfiler/containers/TablePanel';
import Loading from '../components/Loading';
import {
  getViewType,
  isMapView,
} from '../features/ui/fieldProfiler/helpers/functions/view';
import { getDocumentTitle } from '../helpers';
import { PAGES_ROOTS } from '../helpers/navigation';
import BaseTemplate from './BaseTemplate.jsx';
import { selectEditMode } from '../features/ui/fieldProfiler/fieldProfilerSelectors';
import useDidMount from '../hooks/useDidMount';
import { fetchProfileJohnDeere } from '../features/jdProfile/jdProfileSlice';

const Map = React.lazy(() => import('../features/ui/mapView/containers/Map'));

const FieldProfiler = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const editMode = useSelector(selectEditMode);
  const isMap = isMapView(getViewType(location.search));

  useDidMount(() => {
    document.title = getDocumentTitle(t('general.navigation.field-profiler'));
  });

  useDidMount(() => {
    dispatch(fetchProfileJohnDeere());
  });

  return (
    <AuthenticationRedirector>
      <PricingRedirector>
        <BaseTemplate
          route={PAGES_ROOTS.fields}
          headerProps={{
            text: t('general.navigation.field-profiler'),
          }}
        >
          <ToolsPanel key="tools-panel" />
          {
            editMode && !isMap
              && <TablePanel key="instrument-panel" />
          }
          {
            isMap
              && (
                <Suspense
                  key="panel"
                  fallback={<Loading />}
                >
                  <Map />
                </Suspense>
              )
          }
          { isMap && <Legend key="legend" /> }
          { !isMap && <Table key="panel" /> }
        </BaseTemplate>
      </PricingRedirector>
    </AuthenticationRedirector>
  );
};

export default FieldProfiler;
