import React, {
  forwardRef,
  ReactElement,
  Ref,
  useState,
} from 'react';
import { Divider } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Virtuoso } from 'react-virtuoso';

import Autocomplete from '../../../../../components/Autocomplete';
import { AutocompleteItem } from '../../../autocomplete/types/autocomplete';

import './index.scss';

const ITEM_HEIGHT = 37;
const DEFAULT_LISTBOX_HEIGHT = 300;

const calculateListboxHeight = (element: HTMLDivElement | null) => {
  const {
    maxHeight = 0,
    paddingTop = 0,
    paddingBottom = 0,
  } = element ? getComputedStyle(element) : {};

  const maxHeightValue = maxHeight ? parseFloat(maxHeight) : 0;
  const paddingTopValue = paddingTop ? parseFloat(paddingTop) : 0;
  const paddingBottomValue = paddingBottom ? parseFloat(paddingBottom) : 0;

  return maxHeightValue - paddingTopValue - paddingBottomValue;
};

const ListboxComponent = (
  {
    children,
    ...listboxProps
  } : {
    children: ReactElement[],
  },
  ref: Ref<HTMLDivElement>,
) => {
  const [height, setHeight] = useState(DEFAULT_LISTBOX_HEIGHT);

  const handleRefCallback = (element: HTMLDivElement | null) => {
    const maxHeight = calculateListboxHeight(element);

    if (children.length * ITEM_HEIGHT < maxHeight) {
      setHeight(children.length * ITEM_HEIGHT);
    } else if (maxHeight) {
      setHeight(maxHeight);
    }

    if (typeof ref === 'function') {
      ref(element);
    }
  };

  return (
    <div ref={handleRefCallback} {...listboxProps}>
      <Virtuoso
        style={{ height }}
        totalCount={children.length}
        data={children}
        itemContent={(_i, child) => child}
      />
    </div>
  );
};

export default function LabelNameFieldsFilter({
  options,
  value,
  onGetOptions,
  onFilterChange,
}: {
  options: Record<string, string[]>,
  value: AutocompleteItem[],
  onGetOptions: () => void;
  onFilterChange: (value: AutocompleteItem[]) => void;
}) {
  const { t } = useTranslation();

  const renderOption = ({ title, withDivider }: AutocompleteItem) => {
    return (
      <span
        className='label-name-fields-filter__option'
        title={title}
      >
        {title}
        {withDivider && <Divider absolute /> }
      </span>
    );
  };

  return (
    <Autocomplete
      limitTags={4}
      placeholder={t('general.controls.filter-by-label-or-name')}
      placeholderWithValue={t('field-profiler.tools-panel.add-filter')}
      searchLabel={t('field-profiler.tools-panel.search')}
      allValuesLabel={t('field-profiler.tools-panel.all-values')}
      emptyLabel={t('field-profiler.tools-panel.empty')}
      options={options}
      value={value}
      classes={{
        root: 'label-name-fields-filter__labels',
      }}
      data-test="labels-filter"
      renderOption={renderOption}
      onChange={onFilterChange}
      onGetOptions={onGetOptions}
      ListboxComponent={forwardRef(ListboxComponent)}
    />
  );
}
