import React, {
  Fragment,
  MouseEvent,
  Ref,
  forwardRef,
  useCallback,
  useMemo,
} from 'react';
import { Virtuoso } from 'react-virtuoso';
import clsx from 'clsx';
import List, { ListProps } from '@material-ui/core/List';
import ListItem, { ListItemProps } from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import type { ListPanelItem } from '../types';
import { ButtonMenuItem } from '../../ButtonMenu/types';
import ButtonMenu from '../../ButtonMenu';
import Button from '../../Button';

import './index.scss';

const ListComponent = (
  { children, ...props } : ListProps,
  ref: Ref<HTMLDivElement>,
) => {
  return (
    <List
      {...props}
      ref={ref as Ref<HTMLUListElement>}
    >
      {children}
    </List>
  );
};

const ListItemComponent = (
  { children, ...props } : ListItemProps,
) => {
  return (
    <ListItem
      {...props}
      className="list-panel__item"
      button={false}
    >
      {children}
    </ListItem>
  );
};

const MenuItems = ({
  index,
  item,
  menuItems,
  onMenuItemClick,
} : {
  index: number,
  item: ListPanelItem,
  menuItems: ButtonMenuItem[];
  onMenuItemClick?: (m: ButtonMenuItem, i: ListPanelItem, ind: number) => void;
}) => {
  return (
    <ButtonMenu
      isIconButton
      edge="end"
      size="small"
      items={menuItems}
      classes={{
        iconButton: {
          root: 'list-panel__item-menu',
        },
      }}
      onItemClick={(e) => onMenuItemClick?.(e, item, index)}
    >
      <MoreVertIcon fontSize="small" />
    </ButtonMenu>
  );
};

const ListPanel = ({
  title,
  addButtonLabel,
  emptyAddedItemsLabel,
  items = [],
  activeItemsValues,
  menuItems,
  required = false,
  onItemClick,
  onAddClick,
  onMenuItemClick = () => {},
}: {
  title: string;
  addButtonLabel?: string;
  emptyAddedItemsLabel?: string;
  items: ListPanelItem[];
  activeItemsValues: Set<string>;
  menuItems?: ButtonMenuItem[];
  required?: boolean;
  onItemClick: (e: MouseEvent, i: ListPanelItem) => void;
  onAddClick?: () => void;
  onMenuItemClick?: (m: ButtonMenuItem, i: ListPanelItem, ind: number) => void;
}) => {
  const itemRenderer = (index: number, item: ListPanelItem | null) => {
    return (
      <Fragment key={index}>
        {item && (
          <>
            <Button
              className={clsx('list-panel__item-button', {
                'list-panel__item-button-selected': activeItemsValues.has(item.value),
              })}
              onClick={(e) => onItemClick(e, item)}
            >
              {item.title}
            </Button>
            {menuItems && (
              <MenuItems
                index={index}
                item={item}
                menuItems={menuItems}
                onMenuItemClick={onMenuItemClick}
              />
            )}
          </>
        )}
        {!item && emptyAddedItemsLabel && (
          <div className="list-panel__empty-item">
            {emptyAddedItemsLabel}
          </div>
        )}
      </Fragment>
    );
  };

  const headerComponent = useCallback(() => {
    return (
      <>
        {addButtonLabel && onAddClick
          ? (
            <Button
              className="list-panel__add-button"
              onClick={onAddClick}
              startIcon={<AddIcon className="list-panel__add-button-icon"/>}
            >
              {addButtonLabel}
            </Button>
          )
          : null}
      </>
    );
  }, [addButtonLabel, onAddClick]);

  const data = useMemo(() => {
    return !items.length && emptyAddedItemsLabel ? [null] : items;
  }, [items, emptyAddedItemsLabel]);

  return (
    <div className="list-panel">
      <Typography className="list-panel__title">
        {title}
        {required && <span className="required-asterisk">*</span>}
      </Typography>
      <div className="list-panel__list">
        <Virtuoso
          data={data}
          className="list-panel__scroller"
          components={{
            List: forwardRef(ListComponent),
            Item: ListItemComponent,
            Header: headerComponent,
          }}
          itemContent={itemRenderer}
        />
      </div>
    </div>
  );
};

export default ListPanel;
