import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Label } from '../../field/types/field';

interface FieldsListState {
  farm: string;
  filter: {
    name?: string;
    labels?: Label[];
  };
}

const initialState: FieldsListState = {
  farm: '',
  filter: {
    name: '',
    labels: [],
  },
};

export const fieldsListSlice = createSlice({
  name: 'fieldsList',
  initialState,
  reducers: {
    resetFilters(state) {
      state.filter = initialState.filter;
      state.farm = initialState.farm;
    },
    updateFilters(state, action: PayloadAction<{
      farm?: string,
      filter?: {
        name?: string;
        labels?: Label[];
      },
    }>) {
      state.filter = action.payload.filter ?? state.filter;
      state.farm = action.payload.farm ?? state.farm;
    },
  },
});

export const {
  resetFilters,
  updateFilters,
} = fieldsListSlice.actions;

export default fieldsListSlice.reducer;
