import React from 'react';

import { useAppDispatch, useAppSelector } from '../../../../../app/store/helpers/functions';
import { getSmartSamplingTableRows } from '../../helpers/functions/tableRows';
import { generatePinsGroups } from '../../smartSamplingSlice';
import { selectFarmUuid, selectFilter } from '../../../fieldsList/fieldsListSelectors';
import { selectLabelsExpanded } from '../../../fieldProfiler/fieldProfilerSelectors';
import { selectFieldsStatusesEntities } from '../../smartSamplingSelectors';
import { selectAreaUnit, selectApiKey } from '../../../../user/userSelectors';
import { useGetFarmsQuery } from '../../../../farms/farmsAPI';
import FieldProfilerTableComponent from '../../../fieldsList/components/Table';
import { DefaultFieldTableRow } from '../../../fieldsList/types/tableRow';
import { getTableColumns } from '../../helpers/functions/tableColumns';
import useInfiniteFieldsList from '../../../../fields/hooks/useInfiniteFieldsList';

export default function SmartSamplingTable() {
  const dispatch = useAppDispatch();
  const fieldsStatuses = useAppSelector(selectFieldsStatusesEntities);
  const apiKey = useAppSelector(selectApiKey);
  const areaUnit = useAppSelector(selectAreaUnit);
  const labelsExpanded = useAppSelector(selectLabelsExpanded);
  const filter = useAppSelector(selectFilter);
  const farmUuid = useAppSelector(selectFarmUuid);

  const {
    data: farms,
    isFetching: farmsFetching,
  } = useGetFarmsQuery();

  const {
    fields,
    fieldsLoading,
    fieldsLoadComplete,
    triggerNextPage,
  } = useInfiniteFieldsList({
    farmUuid,
    fieldName: filter.name,
    labels: filter.labels,
  });

  const handleLoadMoreRows = () => {
    triggerNextPage();
  };

  const onRowStartButtonClick = (row: DefaultFieldTableRow) => {
    // @ts-expect-error
    dispatch(generatePinsGroups({
      fieldUuid: row.id,
    }));
  };

  const rows = getSmartSamplingTableRows({
    fieldsStatuses,
    fields,
    farms: farms || [],
    apiKey,
  });
  const columns = getTableColumns({
    areaUnit,
    onRowStartButtonClick,
  });

  return (
    <FieldProfilerTableComponent
      rows={rows}
      columns={columns}
      loadComplete={fieldsLoadComplete}
      loading={fieldsLoading || farmsFetching}
      labelsExpanded={labelsExpanded}
      onLoadMoreRows={handleLoadMoreRows}
    />
  );
}
